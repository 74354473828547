<template>
  <v-dialog v-model="isOpen" persistent max-width="500px">
    <v-form @submit.prevent="addContact">
      <v-card>
        <v-card-title>
          <span class="text-h5">Данные контакта</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h6 class="text-subtitle-1">Номер телефона</h6>
            <v-text-field placeholder="Телефон" dense v-mask="'(###) ###-####'" type="tel" outlined v-model="phone" />

            <div class="mb-6">
              <h6 class="text-subtitle-1">Дополнительные поля</h6>

              <v-list class="mx-n4 mt-n3 pa-0">
                <v-list-item v-for="(_, key) in fields" :key="key">
                  <v-list-item-content class="d-flex">
                    <v-row>
                      <v-col cols="4" align-self="center">
                        <v-text-field v-if="hasCreatableFields" placeholder="Название" hide-details type="text" outlined dense v-model="fields[key].name"/>
                        <div v-else>{{ fields[key].name }}</div>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field placeholder="Значение" hide-details type="text" outlined dense v-model="fields[key].value"/>
                      </v-col>
                      <v-col v-if="!hasCreatableFields" cols="auto">
                        <v-btn
                          :disabled="!(fields[key].name && fields[key].value) || (key === 0 && fields.length === 1)"
                          color="red darken-3"
                          style="margin-top: 2px"
                          dark
                          icon
                          type="button"
                          @click.stop="deleteField(key)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <div v-if="hasCreatableFields" class="d-flex">
                <v-btn color="primary" dark small class="ml-auto" type="button" @click.stop="addField">
                  <v-icon left>mdi-plus</v-icon>
                  Добавить поле
                </v-btn>
              </div>
            </div>

            <slot />

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="button" @click.stop="$emit('close')">Отменить</v-btn>
          <v-btn color="hemp" dark type="submit" text>Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VueMaskDirective } from 'v-mask';

export default {
  name: 'DialogCreateNumberBase',
  directives: {
    mask: VueMaskDirective,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    bases: Array,
    defaultFields: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      phone: '',

      fields: [{ name: '', value: '' }],
    };
  },
  computed: {
    ...mapGetters(['BASES']),
    hasCreatableFields() {
      return !this.defaultFields.length;
    },
  },
  watch: {
    isOpen(v) {
      if (v) {
        this.fields = this.defaultFields.length ? this.defaultFields.map(field => ({ name: field, value: '' })) : [{ name: '', value: '' }];
      } else {
        this.phone = '';
        this.fields = [{ name: '', value: '' }];
      }
    },
  },
  methods: {
    ...mapActions(['ADD_NUMBER_WITH_FIELDS_IN_BASE']),
    deleteField(i) {
      if (this.fields.length === 1) return;

      this.fields.splice(i, 1);
    },
    addField() {
      this.fields.push({ name: '', value: '' });
    },
    addContact() {
      const isEmptyField = Number(this.fields.findIndex(field => (field.name && field.value)) === -1);

      Promise.all(
        this.bases.map(
          baseId => this.ADD_NUMBER_WITH_FIELDS_IN_BASE({
            baseId,
            number: this.phone.replace(/[\s\-\(\)]/g, ''),
            fields: isEmptyField ? [] : this.fields,
          }),
        ),
      ).then(() => {
        this.$notify({ text: `Номер ${this.phone} добавлен`, type: 'success' });
        this.$emit('close');
      }).catch(() => {
        this.$notify({ text: 'Не удалось добавить номер', type: 'error' });
      });
    },
  },
};
</script>
