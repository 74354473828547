var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"align-center mb-5"},[_c('v-col',{staticClass:"ma-n2",attrs:{"cols":"12","md":"auto"}},[_c('router-link',{attrs:{"to":{ name: 'createAutocall' }}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus-box-outline")]),_vm._v("Создать кампанию")],1)],1),_c('router-link',{attrs:{"to":{ name: 'contactsList'}}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-playlist-edit")]),_vm._v("Список контактов")],1)],1)],1),_c('v-col',{staticClass:"flex-grow-1 pl-4"},[_c('v-text-field',{attrs:{"placeholder":"Поиск","solo":"","append-icon":"mdi-magnify","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"item-key":"id","multi-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","sort-by":['id'],"sort-desc":[true],"headers":_vm.headers,"items":_vm.filteredAutocalls,"single-select":true,"loading":_vm.IS_LOADING_AUTOCALLS,"footer-props":{
      itemsPerPageOptions: [50,100,-1],
      showFirstLastPage: true,
      firstIcon: 'mdi-chevron-double-left',
      lastIcon: 'mdi-chevron-double-right',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right'
    }},scopedSlots:_vm._u([{key:"item.dateStart",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.dateStart))+" ")]}},{key:"item.bases",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("namesBases")(item.bases))+" ")]}},{key:"item.progress.status",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("status")(item.progress.status))+" ("+_vm._s(_vm._f("progress")(item.progress.countNumCalling,item.progress.countNumAll))+") ")]}},{key:"item.actions",fn:function({ item }){return [_c('AutocallsTableActions',{attrs:{"autocall":item}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }