<template>
  <v-dialog persistent v-model="isOpen" max-width="500px">
    <v-card outlined elevation="6">
      <v-card-title class="text-h5">Вы действительно хотите удалить базу?</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined elevation="4" color="hemp" dark text @click="$emit('close')">Отмена</v-btn>
        <v-btn outlined elevation="4" color="red darken-3" dark text @click="deleteBaseConfirm">Удалить</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DialogDeleteBase',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    baseId: Number,
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['DELETE_BASE']),
    deleteBaseConfirm() {
      this.DELETE_BASE(this.baseId)
        .then(() => {
          this.$notify({ text: 'База удалена', type: 'success' });
        })
        .catch(() => { this.$notify({ text: 'Не удалось удалить базу', type: 'error' }); });
      this.$emit('close');
      if (redirect) {
        this.$router.push({ name: 'contactsList' });
      }
    },
  },
};
</script>
