<template>
  <v-dialog persistent v-model="isOpen" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Вы действительно хотите удалить этот номер?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="hemp" dark text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="red darken-3" dark text @click="deleteItemConfirm">Удалить</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DialogDeleteNumber',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Object,
    },
    baseId: Number,
  },
  methods: {
    ...mapActions(['DELETE_NUMBER_FROM_BASE']),
    deleteItemConfirm() {
      this.DELETE_NUMBER_FROM_BASE({ baseId: +this.baseId, numberId: this.number.id })
        .then(() => {
          this.$notify({ text: 'Номер удален из базы', type: 'success' });
          this.$emit('close');
        })
        .catch(() => { this.$notify({ text: 'Не удалось удалить номер из базы', type: 'error' }); });
    },
  },
};
</script>
