<template>
  <v-dialog v-model="isOpen" persistent max-width="500px">
    <v-form @submit.prevent="saveContact">
      <v-card>
        <v-card-title>
          <span class="text-h5">Данные контакта</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h6 class="text-subtitle-1">Номер телефона</h6>
            <v-text-field placeholder="Телефон" dense v-mask="'# (###) ###-####'" type="tel" outlined readonly :value="phone" />

            <div class="mb-6">
              <h6 class="text-subtitle-1">Дополнительные поля</h6>

              <v-list class="mx-n4 mt-n3 pa-0">
                <v-list-item
                  v-for="({name, value, isDelete}, key) in fields"
                  :key="key"
                  :class="{ 'is-delete': isDelete }"
                >
                  <v-list-item-content class="d-flex">
                    <v-row>
                      <v-col cols="4" align-self="center" :class="{'grey--text': isDelete}">{{ name }}</v-col>
                      <v-col cols="6">
                        <v-text-field placeholder="Значение" hide-details type="text" outlined dense :disabled="isDelete" v-model="fields[key].value"/>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!(name && value)"
                          :color="isDelete ? 'grey' : 'red darken-3'"
                          style="margin-top: 2px"
                          dark
                          icon
                          type="button"
                          @click.stop="isDelete ? restoreField(key) : deleteField(key)"
                        >
                          <v-icon>{{ isDelete ? 'mdi-backup-restore' :  'mdi-close' }}</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-for="({ name }, key) in emptyFields" :key="fields.length + key">
                  <v-list-item-content class="d-flex">
                    <v-row>
                      <v-col cols="4" align-self="center">{{ name }}</v-col>
                      <v-col cols="6">
                        <v-text-field placeholder="Значение" hide-details type="text" outlined dense v-model="emptyFields[key].value"/>
                      </v-col>
                      <v-col cols="auto"></v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="button" @click.stop="$emit('close')">Закрыть</v-btn>
          <v-btn color="hemp" dark type="submit" text>Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { VueMaskDirective } from 'v-mask';

export default {
  name: 'DialogEditNumber',
  directives: {
    mask: VueMaskDirective,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Object,
    },
    baseId: Number,
    typesFields: Array,
  },
  data() {
    return {
      phone: '',

      fields: [],

      emptyFields: [],
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.phone = this.number.num;
        this.fields = this.number.fields.map(f => ({ ...f, isDelete: false }));

        // все типы полей у номера
        const fieldsName = [];

        this.number.fields.forEach(field => {
          if (!fieldsName.includes(field.name)) {
            fieldsName.push(field.name);
          }
        });

        this.emptyFields = this.typesFields.filter(f => !fieldsName.includes(f)).map(f => ({ name: f, value: '' }));
      }
    },
  },
  methods: {
    ...mapActions(['DELETE_FIELD_IN_NUMBER', 'UPDATE_FIELD_IN_NUMBER', 'ADD_FIELDS_FOR_NUMBER']),
    deleteField(i) {
      this.fields[i].isDelete = true;
    },
    restoreField(i) {
      this.fields[i].isDelete = false;
    },
    saveContact() {
      Promise.all([
        this.addFields(),
        this.updateFields(),
        this.deleteFields(),
      ])
        .then(() => {
          this.$notify({ text: 'Данные сохранены', type: 'success' });
          this.$emit('close');
        })
        .catch(() => { this.$notify({ text: 'Не удалось сохранить данные', type: 'error' }); });
    },
    // создать в контакте можем только одно поле одним запросом
    async addFields() {
      await Promise.all(
        this.emptyFields
          .filter(field => field.value)
          .map(field => this.ADD_FIELDS_FOR_NUMBER({
            baseId: this.baseId,
            numberId: this.number.id,
            field,
          })),
      );
    },
    // обновить в контакте можем только одно поле одним запросом
    async updateFields() {
      /* eslint array-callback-return: "off" */
      const updatedFields = this.fields
        .filter(f => f.id)
        .filter(f => {
          const { name, value } = this.number.fields.find(oldField => oldField.id === f.id);

          if (name !== f.name || value !== f.value) {
            return f;
          }
        });

      await Promise.all(
        updatedFields.map(
          field => this.UPDATE_FIELD_IN_NUMBER({
            baseId: this.baseId,
            numberId: this.number.id,
            field,
          }),
        ),
      );
    },
    // удалить в контакте можем только одно поле одним запросом
    async deleteFields() {
      await Promise.all(
        this.fields
          .filter(f => f.isDelete)
          .map(
            field => (
              this.DELETE_FIELD_IN_NUMBER({
                baseId: this.baseId,
                numberId: this.number.id,
                fieldId: field.id,
              })
            ),
          ),
      );
    },
  },
};
</script>

<style scoped lang="scss">
.is-delete {
  position: relative;

  &::before {
    opacity: 1 !important;
    position: absolute;
    top: 50%;
    left: 10px;
    right: 85px;
    content: "";
    height: 2px;
    background: #999;
  }
}
</style>
