<template>
  <v-container>
    <!-- <FunctionalityUnavailabilityNotification /> -->

    <v-row class="align-center mb-5">
      <v-col cols="12" md="auto" class="ma-n2">
        <router-link :to="{ name: 'createAutocall' }"><v-btn color="primary" dark class="ma-2"><v-icon left dark >mdi-plus-box-outline</v-icon>Создать кампанию</v-btn></router-link>
        <router-link :to="{ name: 'contactsList'}"><v-btn color="primary" dark class="ma-2"><v-icon left dark >mdi-playlist-edit</v-icon>Список контактов</v-btn></router-link>
      </v-col>

      <v-col class="flex-grow-1 pl-4">
        <v-text-field
          placeholder="Поиск"
          solo
          append-icon="mdi-magnify"
          hide-details="auto"
          v-model="search"
        />
      </v-col>
    </v-row>

    <v-data-table
      class="elevation-1 table"
      item-key="id"
      multi-sort
      loading-text="Загрузка... Пожалуйста, подождите"
      :sort-by="['id']"
      :sort-desc="[true]"
      :headers="headers"
      :items="filteredAutocalls"
      :single-select="true"
      :loading="IS_LOADING_AUTOCALLS"
      :footer-props="{
        itemsPerPageOptions: [50,100,-1],
        showFirstLastPage: true,
        firstIcon: 'mdi-chevron-double-left',
        lastIcon: 'mdi-chevron-double-right',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right'
      }"
    >
      <template v-slot:item.dateStart="{ item }">
        {{ item.dateStart | datetime }}
      </template>

      <template v-slot:item.bases="{ item }">
        {{ item.bases | namesBases }}
      </template>

      <template v-slot:item.progress.status="{ item }">
        {{ item.progress.status | status }} ({{ item.progress.countNumCalling | progress(item.progress.countNumAll) }})
      </template>

      <template v-slot:item.actions="{ item }">
        <AutocallsTableActions :autocall="item" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import statuses from '../../mappers/statuses';
// import FunctionalityUnavailabilityNotification from '../../components/FunctionalityUnavailabilityNotification.vue';
import AutocallsTableActions from './components/AutocallsTableActions.vue';

export default {
  name: 'TableAutocalls',
  // components: { AutocallsTableActions, FunctionalityUnavailabilityNotification },
  components: { AutocallsTableActions },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Дата', value: 'dateCreated', align: 'center' },
        { text: 'Название', value: 'name', align: 'center' },
        { text: 'Группа', value: 'group.name', align: 'center' },
        { text: 'Контакты', value: 'bases', align: 'center', sortable: false },
        { text: 'Всего номеров', value: 'progress.countNumAll', align: 'center', sortable: false },
        { text: 'Обработано', value: 'progress.countNumCalling', align: 'center', sortable: false },
        { text: 'Статус', value: 'progress.status', align: 'center' },
        { text: '', value: 'actions', align: 'end', sortable: false },
      ],
    };
  },
  filters: {
    datetime(date) {
      if (!date) return '';

      return new Date(date).toLocaleString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    namesBases(bases) {
      if (!bases.length) return '';

      return bases.map(b => (b?.name || '-')).join(', ');
    },
    status(key) {
      return statuses[key] ?? key;
    },
    progress(countCalling, allCount) {
      const percent = (countCalling / allCount) * 100;

      return Number.isNaN(percent) || percent === Infinity
        ? '0%'
        : percent > 100
          ? '100%'
          : `${percent.toLocaleString('ru-RU', { useGrouping: false, maximumFractionDigits: 2 })}%`;
    },
  },
  computed: {
    ...mapState({
      loadingAutocallInfo: state => state.billing.loadingAutocallInfo,
    }),
    ...mapGetters([
      'AUTOCALLS', 'IS_LOADING_AUTOCALLS', 'BASES', 'CONNECTED_BASE_IDS',
      'IS_LOADING_BASES', 'GROUPS', 'IS_LOADING_GROUPS', 'NUMBERS', 'GET_PROGRESS',
    ]),
    autocallsData() {
      return this.AUTOCALLS
        ? this.AUTOCALLS.map(autocall => {
          const group = {
            id: autocall.group,
            name: this.GROUPS?.find(g => +g.id === +autocall.group)?.name || 'Не выбрано',
          };

          const bases = this.CONNECTED_BASE_IDS[autocall.id]
            ? this.CONNECTED_BASE_IDS[autocall.id].map(baseId => this.BASES.find(b => +b.id === +baseId))
            : [];

          const progress = this.GET_PROGRESS(autocall.id);

          return {
            id: autocall.id,
            dateCreated: autocall.dateCreated,
            name: autocall.name,
            isHidden: autocall.isHidden,
            group,
            bases,
            progress,
          };
        })
        : [];
    },
    filteredAutocalls() {
      return !this.search
        ? this.autocallsData
        : this.autocallsData.filter(autocall => (
          ~autocall.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase())
          || ~autocall.status.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase())
          || ~autocall.group.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase())
          || autocall.bases.find(base => ~base.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()))
        ));
    },
    isLoadingTable() {
      return this.AUTOCALLS === null
      && this.BASES === null && this.GROUPS === null;
    },
  },
};
</script>

<style scoped>
.table-action-menu .v-list-item {
  min-height: 25px;
}
.table {
    padding-right: 5px;
    padding-left: 5px;
}
</style>
