<template>
  <v-dialog persistent v-model="isOpen" max-width="500px">
    <v-card outlined elevation="6">
      <v-card-title class="text-h5">Остановить кампанию?</v-card-title>
      <v-divider></v-divider>
      <v-spacer></v-spacer>
      <v-card-subtitle class="text-h7 pt-2">
        <b>Внимание!</b>
        <br>
        Для постановки на паузу кампании с последующим продолжением воспользуйтесь кнопкой "пауза"
      </v-card-subtitle>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined elevation="4" color="hemp" dark text @click="$emit('close')"
          >Отмена</v-btn>
        <v-btn outlined elevation="4" color="red darken-3" dark text @click="stopAutocall">Остановить</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DialogStopAutocall',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    autocall: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(['STOP_AUTOCALL']),
    stopAutocall() {
      this.STOP_AUTOCALL({
        groupId: this.autocall.group.id,
        autocallId: this.autocall.id,
      })
        .then(() => { this.$notify({ text: 'Кампания остановлена', type: 'success' }); })
        .catch(e => { this.$notify({ text: e, type: 'error' }); });
      this.$emit('close');
    },
  },
};
</script>
