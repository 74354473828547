<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="auto" class="ma-n2">
        <router-link to="/"><v-btn color="primary" dark class="ma-2">Список кампаний</v-btn></router-link>
        <router-link :to="{ name: 'contactsList' }"><v-btn color="primary" dark class="ma-2">Список контактов</v-btn></router-link>
      </v-col>
    </v-row>

    <div v-if="base" class="mt-5">
      <v-row>
        <v-col cols="12" class="text-h5">
          Контакты в списке: <strong class="ml-2">“{{ base.name }}”</strong>
        </v-col>
        <v-col cols="12" class="text-body-1">
          Всего контактов: <strong class="ml-2">{{ base.quantity }}</strong>
        </v-col>
      </v-row>

      <v-row class="mt-6 align-center">
        <v-col cols="auto">
          <v-btn color="primary" dark @click="openCreateDialog">
            <v-icon left>mdi-plus</v-icon>
            Добавить контакт
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="red darken-3" dark @click="openDeleteBaseDialog">
            <v-icon left>mdi-delete</v-icon>
            Удалить базу
          </v-btn>
        </v-col>

        <v-col class="flex-grow-1 pl-4">
          <v-text-field
              placeholder="Поиск"
              solo
              append-icon="mdi-magnify"
              hide-details="auto"
              v-model="search"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="filteredNumbers"
            :loading="!base.id || isLoading"
            loading-text="Загрузка... Пожалуйста подождите"
            multi-sort
            item-key="id"
            class="elevation-2"
            @pagination="handlePagination"
            :footer-props="{
              itemsPerPageOptions: [50,100,-1],
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >
            <template v-slot:item.phone="{ item }">
              {{ item.phone | phone }}
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-inline-flex">
                <v-btn
                  icon
                  :color="$vuetify.theme.dark ? 'white' : 'grey darken-3'"
                  small
                  dark
                  @click.stop="openEditDialog(item.id)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="red darken-3" class="ml-1" small dark @click.stop="openDeleteDialog(item.id)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:footer.page-text="options">
              <span>{{ options.pageStart }}-{{ options.pageStop }} из {{ base.quantity }}</span>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <DialogDeleteNumber
      :is-open="deleteDialog"
      :number="editedNumber"
      :base-id="+this.$route.params.baseId"
      @close="closeDeleteDialog"
    />
    <DialogEditNumber
      :is-open="editDialog"
      :number="editedNumber"
      :base-id="+this.$route.params.baseId"
      :types-fields="baseFields"
      @close="closeEditDialog"
    />
    <DialogCreateNumber
      ref="dialogCreateNumber"
      :is-open="createDialog"
      :base-id="+this.$route.params.baseId"
      :default-fields="baseFields"
      @close="closeCreateDialog"
    />
    <DialogDeleteBase
      :is-open="deleteBaseDialog"
      :base-id="deleteBaseId"
      :redirect="true"
      @close="closeDeleteBaseDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VueMaskFilter } from 'v-mask';
import DialogDeleteNumber from './components/DialogDeleteNumber.vue';
import DialogEditNumber from './components/DialogEditNumber.vue';
import DialogCreateNumber from './components/dialogCreateNumber/DialogCreateNumber.vue';
import DialogDeleteBase from './components/DialogDeleteBase.vue';

export default {
  name: 'ContactsListTableItem',
  components: { DialogDeleteNumber, DialogEditNumber, DialogCreateNumber, DialogDeleteBase },
  data() {
    return {
      isLoading: false,

      search: '',

      dialogCreateList: false,
      dialogCreateListName: '',

      selectedRows: [],

      editedNumber: {},

      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      deleteBaseDialog: false,
      deleteBaseId: null,
    };
  },
  computed: {
    ...mapGetters(['BASES']),
    base() {
      return this.BASES.find(b => b.id === +this.$route.params.baseId);
    },
    baseFields() {
      const fieldsFirstElement = this.base?.list.length ? this.base.list[0].fields : [];

      return fieldsFirstElement.map(field => field.name);
    },
    headers() {
      return [
        { text: 'Телефон', align: 'start', value: 'phone' },
        ...this.baseFields.map(name => ({ text: name, align: 'center', value: name })),
        { align: 'end', value: 'actions' },
      ];
    },
    numbersForTable() {
      return this.base.list.map(number => ({
        id: number.id,
        phone: number.num,
        ...Object.fromEntries(number.fields.map(field => ([[field.name], field.value]))),
      }));
    },
    filteredNumbers() {
      return !this.search
        ? this.numbersForTable
        : this.numbersForTable.filter(number => (
          Object.values(number).find(n => ~String(n).toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()))
        ));
    },
  },
  methods: {
    ...mapActions(['DELETE_BASE', 'GET_NUMBERS_FROM_BASE']),
    openDeleteBaseDialog() {
      this.deleteBaseDialog = true;
      this.deleteBaseId = this.$route.params.baseId;
    },
    closeDeleteBaseDialog() {
      this.deleteBaseDialog = false;
      this.deleteBaseId = null;
    },
    openEditDialog(numberId) {
      this.editedNumber = this.base.list.find(n => n.id === numberId);
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editedNumber = {};
      this.editDialog = false;
    },
    openDeleteDialog(numberId) {
      this.editedNumber = this.base.list.find(n => n.id === numberId);
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.editedNumber = {};
      this.deleteDialog = false;
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    closeCreateDialog() {
      this.createDialog = false;
    },
    handlePagination(opts) {
      const { pageStop, itemsLength } = opts;

      if (pageStop === itemsLength && this.base.quantity > itemsLength) {
        this.isLoading = true;

        this.GET_NUMBERS_FROM_BASE({
          baseId: this.$route.params.baseId,
          page: this.base.loadedPages + 1,
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
  },
  filters: {
    phone(val) {
      if (val.length === 7) {
        return VueMaskFilter(val, '8 (391) ###-####');
      }
      if (val.length === 10) {
        return VueMaskFilter(val, '8 (###) ###-####');
      }
      return VueMaskFilter(val, '# (###) ###-####');
    },
  },
};
</script>

<style scoped>
.table-action-menu .v-list-item {
  min-height: 35px;
}
</style>
