<template>
  <DialogCreateNumberBase :is-open="isOpen" :bases="selectedBases" @close="$emit('close')">
    <v-row v-if="BASES !== null" no-gutters class="flex-column">
      <h6 class="text-subtitle-1">Список баз для добавления номера</h6>

      <v-autocomplete
        :items="BASES"
        item-text="name"
        item-value="id"
        outlined
        chips
        small-chips
        placeholder="Списки"
        multiple
        hide-details
        v-model="selectedBases"
      />
      <v-checkbox v-model="isAllSelected" hide-details label="Выбрать все"/>
    </v-row>
  </DialogCreateNumberBase>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogCreateNumberBase from './DialogCreateNumberBase.vue';

export default {
  name: 'DialogCreateNumberForListBases',
  components: { DialogCreateNumberBase },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedBases: [],
    };
  },
  computed: {
    ...mapGetters(['BASES']),
    isAllSelected: {
      get() {
        return this.BASES && this.BASES.length === this.selectedBases.length;
      },
      set(val) {
        if (val) {
          this.selectedBases = [...this.BASES];
        } else {
          this.selectedBases = [];
        }
      },
    },
  },
  watch: {
    isOpen(v) {
      if (!v) {
        this.selectedBases = [];
      }
    },
  },
};
</script>
