<template>
  <v-dialog v-model="isOpen" persistent fullscreen transition="dialog-bottom-transition">
    <v-card rounded="0">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Импорт контактов</v-toolbar-title>
      </v-toolbar>
      <v-container>
      </v-container>

      <v-card-text>
        <ImportContacts
          ref="importContacts"
          :visible-types="importTypes"
          :target-base="targetBase"
          @after-import="afterImport"
        />
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import ImportContacts from '../../../components/import-contacts/ImportContacts.vue';
import types from '../../../components/import-contacts/Steps/Step1/Importers/Internal/TypeImporters';

export default {
  name: 'DialogImportNumbers',
  components: { ImportContacts },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    targetBase: Object,
  },
  data() {
    return {
      importTypes: types.TYPE_TEXT | types.TYPE_FILE,
    };
  },
  watch: {
    isOpen(v) {
      if (!v) {
        this.$refs.importContacts.isProcess = false;
        this.$refs.importContacts.columnsData = null;
        this.$refs.importContacts.contacts = null;
        this.$refs.importContacts.selectedListForUpload = null;
        this.$refs.importContacts.step = 1;
      }
    },
  },
  methods: {
    afterImport(base) {
      this.$router.push({ name: 'contactsList.contact', params: { baseId: base.id } });
    },
  },
};
</script>
