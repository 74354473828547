<template>
  <v-dialog persistent v-model="isOpen" max-width="500px">
    <v-card outlined elevation="6">
      <v-card-title class="text-h5">Удалить кампанию?</v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined elevation="4" color="hemp" dark text @click="$emit('close')">Отмена</v-btn>
        <v-btn outlined elevation="4" color="red darken-3" dark text @click="deleteAutocall">Удалить</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DialogDeleteAutocall',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    autocall: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(['DELETE_AUTOCALL']),
    deleteAutocall() {
      this.DELETE_AUTOCALL({
        groupId: this.autocall.group.id,
        autocallId: this.autocall.id,
      })
        .then(() => {
          this.$notify({ text: 'Кампания удалена', type: 'success' });
        })
        .catch(() => { this.$notify({ text: 'Не удалось удалить рассылку', type: 'error' }); });
      this.$emit('close');
    },
  },
};
</script>
