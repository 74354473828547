<template>
  <div class="d-inline-flex">
    <v-menu
      bottom
      left
      origin="top right"
      offset-x
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small class="ml-1" v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
      </template>
      <v-list class="table-action-menu">
        <v-list-item v-if="status === statusTypes.RUNNING" link @click="pause">
          <v-list-item-title><v-icon left dark color="warning" class="pr-3">mdi-pause</v-icon>Поставить на паузу</v-list-item-title>
        </v-list-item>
        <v-list-item link v-if="![statusTypes.BLOCKED, statusTypes.RUNNING, statusTypes.PAUSED].includes(status)" @click="start">
          <v-list-item-title><v-icon left dark color="hemp" class="pr-3">mdi-play</v-icon>Старт</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="status === statusTypes.PAUSED" link @click="resume">
          <v-list-item-title><v-icon left dark color="hemp" class="pr-3">mdi-play</v-icon>Возобновить</v-list-item-title>
        </v-list-item>
        <v-list-item link v-if="![statusTypes.BLOCKED, statusTypes.READY, statusTypes.FINISHED].includes(status)" @click="stop">
          <v-list-item-title><v-icon left dark color="tomato" class="pr-3">mdi-stop</v-icon>Завершить</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="edit">
          <v-list-item-title><v-icon left dark color="accent" class="pr-3">mdi-pencil</v-icon>Редактировать</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="createBasedOn">
          <v-list-item-title><v-icon left dark color="accent" class="pr-3">mdi-content-copy</v-icon>Скопировать</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="statisticClick">
          <v-list-item-title><v-icon left dark color="accent" class="pr-3">mdi-poll</v-icon>Статистика</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="downloadStatistic">
          <v-list-item-title><v-icon left dark color="accent" class="pr-3">mdi-download</v-icon>Скачать отчет</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="deleteAutocall">
          <v-list-item-title><v-icon left dark color="primary" class="pr-3">mdi-delete</v-icon>Удалить</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <DialogStartAutocall
      :is-open="startDialog"
      :autocall="this.autocall"
      @close="closeStartDialog"
    />
    <DialogStopAutocall
      :is-open="stopDialog"
      :autocall="this.autocall"
      @close="closeStopDialog"
    />
    <DialogDeleteAutocall
      :is-open="deleteDialog"
      :autocall="this.autocall"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { statusTypes } from '../../../mappers/statuses';
import DialogStartAutocall from './DialogStartAutocall.vue';
import DialogStopAutocall from './DialogStopAutocall.vue';
import DialogDeleteAutocall from './DialogDeleteAutocall.vue';

export default {
  name: 'AutocallsTableActions',
  components: { DialogStartAutocall, DialogStopAutocall, DialogDeleteAutocall },
  props: {
    autocall: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusTypes,
      startDialog: false,
      stopDialog: false,
      deleteDialog: false,
    };
  },
  computed: {
    ...mapGetters(['GET_PROGRESS']),
    status() {
      return this.GET_PROGRESS(this.autocall.id)?.status || '';
    },
  },
  methods: {
    ...mapActions([
      'DOWNLOAD_STATISTIC_AS_EXCEL', 'PAUSE_AUTOCALL', 'RESUME_AUTOCALL',
    ]),
    edit() {
      this.$router.push({ name: 'editAutocall', params: { groupId: this.autocall.group.id, autocallId: this.autocall.id } });
    },
    pause() {
      this.PAUSE_AUTOCALL({
        groupId: this.autocall.group.id,
        autocallId: this.autocall.id,
      })
        .then(() => { this.$notify({ text: 'Кампания поставлена на паузу', type: 'success' }); })
        .catch(e => { this.$notify({ text: e, type: 'error' }); });
    },
    resume() {
      this.RESUME_AUTOCALL({
        groupId: this.autocall.group.id,
        autocallId: this.autocall.id,
      })
        .then(() => {
          this.$notify({ text: 'Кампания возобновлена', type: 'success' });
        })
        .catch(e => { this.$notify({ text: e, type: 'error' }); });
    },
    downloadStatistic() {
      this.DOWNLOAD_STATISTIC_AS_EXCEL({
        groupId: this.autocall.group.id,
        autocallId: this.autocall.id,
      })
        .catch(() => { this.$notify({ text: 'Не удалось скачать рассылку', type: 'error' }); });
    },
    createBasedOn() {
      this.$router.push({ name: 'createAutocall', params: { groupId: this.autocall.group.id, autocallId: this.autocall.id } });
    },
    statisticClick() {
      this.$router.push({ name: 'statistic', query: { group: this.autocall.group.id, autocall: this.autocall.id } });
    },
    start() {
      this.startDialog = true;
    },
    stop() {
      this.stopDialog = true;
    },
    deleteAutocall() {
      this.deleteDialog = true;
    },
    closeStartDialog() {
      this.startDialog = false;
    },
    closeStopDialog() {
      this.stopDialog = false;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
  },
};
</script>
