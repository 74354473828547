<template>
  <DialogCreateNumberBase :is-open="isOpen" :bases="[baseId]" :default-fields="defaultFields" @close="$emit('close')" />
</template>

<script>
import DialogCreateNumberBase from './DialogCreateNumberBase.vue';

export default {
  name: 'DialogCreateNumber',
  components: { DialogCreateNumberBase },
  props: {
    baseId: Number,
    defaultFields: {
      type: Array,
      default: () => ([]),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
