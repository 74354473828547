<template>
  <router-view />
</template>

<script>
export default {
  name: 'TableAutoCall',
  data() {
    return {
      search: '',
    };
  },
};
</script>
