<template>
  <v-container>
    <v-row class="align-center">
      <v-col cols="12" md="auto" class="ma-n2">
        <router-link to="/"><v-btn color="primary" dark class="ma-2"><v-icon left dark >mdi-keyboard-backspace</v-icon>Назад</v-btn></router-link>
      </v-col>

      <v-col class="flex-grow-1 pl-4">
        <v-text-field
          placeholder="Поиск"
          solo
          append-icon="mdi-magnify"
          hide-details="auto"
          v-model="search"
        />
      </v-col>
    </v-row>

    <div class="mt-3">
      <v-row>
        <v-col>
          Контактов во всех списках: <strong class="ml-2">{{ TOTAL_ALL_NUMBERS.toLocaleString() }}</strong>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mx-n2">
          <v-btn class="mx-2" @click="dialogImportContacts.isOpen = true">Импортировать контакты</v-btn>
          <v-btn class="mx-2" @click="dialogCreateContactForListBases = true">Добавить контакт</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            ref="table"
            @update:sort-by="flushNew"
            :sort-desc="[true]"
            :headers="headers"
            :custom-sort="customSort"
            :items="filteredBasesPhones"
            multi-sort
            :loading="IS_LOADING_BASES"
            loading-text="Загрузка... Пожалуйста, подождите"
            class="elevation-2"
            :items-per-page="10"
            @click:row="handleClickRow"
            :footer-props="{
              itemsPerPageOptions: [50,100,-1],
              showFirstLastPage: true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >
            <template v-slot:item.name="{ item }">
              <div class="d-inline-flex flex-column py-2">
                <span class="text-h6">{{ item.name }}</span>
                <v-row no-gutters class="mx-n1 mt-2">
                  <v-btn
                    x-small text class="mx-1"
                    dark
                    :color="$vuetify.theme.dark ? 'primary' : 'primary'"
                    @click.stop="openCreateNumberDialog(item.id)"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Добавить
                  </v-btn>
                  <v-btn
                    x-small text class="mx-1"
                    dark
                    :color="$vuetify.theme.dark ? 'primary' : 'primary'"
                    @click.stop="openDialogImportContacts(item)"
                  >
                    <v-icon left>mdi-inbox-arrow-down</v-icon>
                    Импортировать
                  </v-btn>
                </v-row>
              </div>
            </template>

            <template v-slot:item.dateCreated="{ item }">
              {{ item.dateCreated | formatDate }}
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-inline-flex">
                <v-btn
                  icon
                  :color="$vuetify.theme.dark ? 'white' : 'grey darken-3'"
                  dark
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="red darken-3" class="ml-1" dark @click.stop="openDeleteBaseDialog(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-row>

      <DialogImportNumbers
        ref="DialogImportContacts"
        :is-open="dialogImportContacts.isOpen"
        :target-base="dialogImportContacts.targetBase"
        @close="closeDialogImportContacts"
      />
      <DialogCreateNumberForListBases
        ref="DialogCreateContactForListBases"
        :is-open="dialogCreateContactForListBases"
        @close="dialogCreateContactForListBases = false"
      />
      <DialogCreateNumber
        ref="DialogCreateContactInBase"
        :is-open="dialogCreateContactInBase.isOpen"
        :base-id="dialogCreateContactInBase.baseId"
        :default-fields="dialogCreateContactInBase.fields"
        @close="closeCreateNumberDialog"
      />
    </div>
    <DialogDeleteBase
      :is-open="deleteDialog"
      :base-id="deleteBaseId"
      @close="closeDeleteBaseDialog"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { sortItems } from 'vuetify/lib/util/helpers';
import DialogImportNumbers from './components/DialogImportNumbers.vue';
import DialogCreateNumber from './components/dialogCreateNumber/DialogCreateNumber.vue';
import DialogCreateNumberForListBases from './components/dialogCreateNumber/DialogCreateNumberForListBases.vue';
import DialogDeleteBase from './components/DialogDeleteBase.vue';

export default {
  name: 'ContactsListTable',
  components: {
    DialogImportNumbers,
    DialogCreateNumber,
    DialogCreateNumberForListBases,
    DialogDeleteBase,
  },
  data() {
    return {
      search: '',

      dialogCreateContactForListBases: false,

      dialogImportContacts: {
        isOpen: false,
        targetBase: null,
      },

      dialogCreateContactInBase: {
        isOpen: false,
        baseId: null,
        fields: [],
      },

      headers: [
        { text: 'Название', align: 'start', value: 'name' },
        { text: 'Дата создания/обновления', align: 'center', value: 'dateCreated' },
        { text: 'Количество контактов', align: 'center', value: 'quantity' },
        { text: 'Примечание', value: 'comment' },
        { align: 'right', value: 'actions' },
      ],

      new: {},

      deleteDialog: false,
      deleteBaseId: null,
    };
  },
  computed: {
    ...mapGetters(['BASES', 'IS_LOADING_BASES', 'TOTAL_ALL_NUMBERS']),
    filteredBasesPhones() {
      return !this.search
        ? this.BASES
        : this.BASES.filter(base => (
          ~base.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase())
        ));
    },
  },
  methods: {
    ...mapActions(['DELETE_BASE']),
    handleClickRow(item) {
      this.$router.push({ name: 'contactsList.contact', params: { baseId: item.id } });
    },
    customSort(items, sortBy, sortDesc, locale) {
      if (!this.$refs.table) {
        return items;
      }

      return sortItems(items, sortBy, sortDesc, locale, this.$refs.table.columnSorters)
        .sort((a, b) => {
          const aIsNew = this.new[a.id] || 0;
          const bIsNew = this.new[b.id] || 0;

          return bIsNew - aIsNew;
        });
    },
    flushNew() {
      this.new = {};
    },
    openDeleteBaseDialog(id) {
      this.deleteDialog = true;
      this.deleteBaseId = id;
    },
    closeDeleteBaseDialog() {
      this.deleteDialog = false;
      this.deleteBaseId = null;
    },
    openCreateNumberDialog(baseId) {
      this.dialogCreateContactInBase = true;

      const base = this.BASES.find(b => b.id === +baseId);
      const fields = base.list.length ? base.list[0].fields.map(field => field.name) : [];

      this.dialogCreateContactInBase = {
        isOpen: true,
        baseId,
        fields,
      };
    },
    closeCreateNumberDialog() {
      this.dialogCreateContactInBase = {
        isOpen: false,
        baseId: null,
        fields: [],
      };
    },
    openDialogImportContacts(item) {
      this.dialogImportContacts = {
        isOpen: true,
        targetBase: item,
      };
    },
    closeDialogImportContacts() {
      this.dialogImportContacts = {
        isOpen: false,
        targetBase: null,
      };
    },
  },
  filters: {
    formatDate(date) {
      return (new Date(date)).toLocaleDateString();
    },
  },
};
</script>

<style scoped>
.table-action-menu .v-list-item {
  min-height: 35px;
}
</style>
